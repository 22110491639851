.loading-container{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.8s 0.2s;
  pointer-events: none;
}

.loading-container.loading, .loading-container.error{
  opacity: 1;
}

.loading-container.loaded{
  transition: opacity 0.3s;
  opacity: 0;
}

.loading-container.loaded .loading-shape{
  animation-play-state: paused;
}

.loading-container.fading .loading-shape{
    animation-play-state: paused;
}

.loading-container .loading-shape{
  border-radius: 100%;
  background-color: #520303;
  animation: poping 1.8s infinite;
  opacity: 0.7;
  height: 20px;
  width: 20px;
  transform: scale(0);
  margin: 2px;
}

.loading-container.white-color .loading-shape{
  background-color: #fff;
}
.loading-container .shape2{
  animation-delay: 0.6s;
  background-color: rgb(53, 47, 69);
}

.loading-container .shape3{
    animation-delay: 1.2s;
}

@keyframes poping {
  25%{
    transform: scale(1);
  }
  50%{
    transform: scale(0);
  }
  100%{
    transform: scale(0);
  }
}
