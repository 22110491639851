.about{
  overflow: hidden;
}

.about .paragrah{
  min-height: 80vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
  align-items: stretch;
}

.about .paragrah .gallery-overview {
  max-height: none;
}

.about .contact ul{
  list-style: none;
  padding: 0;
}

.about #kontakt{
  font-size: 2rem;
}

.about #kontakt a::before{
  background-color: #520303
}

.about .contact-art .contact{
display: flex;
min-height: 80vh;
align-items:center;
}

.about .contact-art .contact .name{
position: relative;
}


.about .contact-art .contact .info{
width: 46%;
margin-right: 8%;
}
.about .contact-art .contact .opening-hours{
width: 46%;
text-transform: uppercase;
font-weight: 600;
}

.about .contact-art .contact .opening-hours div:first-child{
font-weight: 900;
}

.about .contact-art .contact .opening-hours .empty{
min-height: 10px;
}

.about .contact-art .contact .opening-hours .empty+div{
font-weight: 900;
}

.about .entered article{
  opacity: 1;
  animation: appear-expose 0.5s 0.5s both;
}

.about .omited article{
  opacity: 1;
}


@media (max-width:1024px) {
  .about{
    font-size: 5vmin;
  }

  .about #kontakt{
    font-size: 5vmin;
    background: none;
  }

  .about .paragrah{
    min-height: 500px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .about .contact-art .contact .info{
  width: 100%;
  margin-right: 0;
  }
  .about .contact-art .contact .opening-hours{
    width: 100%;
  }

  .about .contact-art .contact{
  flex-direction: column;
}
}
