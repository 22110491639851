.banner {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.greeting{
  color: rgb(53, 47, 69);
  position: absolute;
  display: flex;
  height: 40%;
  align-items: center;
  text-align: right;
  padding: 5%;
  max-width: 60%;
  animation: appear-jump-leave 6s forwards;
  font-size: 2vw;
  right: 0;
}

.banner .slider-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.banner .show-calendar-focus{
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
}

.banner .squere-container{
position: absolute;
left: 0;
bottom: 0;
transform-origin: bottom left;
animation: none;
transition: transform 1s;
z-index: 1;
height: 100%;
width: 100vw;
pointer-events: none;
}

.calendar-container{
  background-color: #520303;
  pointer-events: auto;
  position: absolute;
  height: calc(100% - 149px);
  top: 149px;
  z-index: 10;
  padding: 1rem;
}

.calendar-container::before{
content: "";
z-index: -20;
background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='51.172' height='48.072'%3E%3Cpath d='M0 0v48.072h.096A50 50 0 0150 0zm50 0a50 50 0 011.172.059V0z' fill='%23520303'/%3E%3C/svg%3E") no-repeat top / contain ;
position: absolute;
left: 100%;
top:0;
width: 150px;
height: 150px
}

.banner .calendar-button{
  z-index: 10;
  display: none;
  position: absolute;
  color: white;
  margin: 2vw;
  transition: opacity 0.7s;
  background-color: transparent;
  border-radius: 5%;
  height: 24vw;
  width: 24vw;
}

.schelude-area{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: calc(100% - 400px);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s;
  color: white;
  font-size: 1.3em;
}

.banner.date-checked .schelude-area{
  pointer-events: auto;
  opacity: 1;
}

.banner.date-hovered .schelude-area{
  opacity: 1
}

.schelude-background{
z-index: 0;
background-color: #520303;
position: absolute;
left: 0;
top:0;
width: 100vw;
height: 100vh;
content: "";
transition: transform 0.4s;
transform-origin: left;
transform: scale(0,1);
background: linear-gradient(45deg, #520303 60%, rgb(53, 47, 69));
}

.calendar-hovered .schelude-background, .date-hovered .schelude-background{
  transform: scale(1,1);
}

.banner-deco{
  height: 10%;
  width: 50%;
  z-index: 1;
  position: absolute;
  background-color: #fff;
}

.banner-deco1{
  right: 0;
  bottom: 0;
}

.banner-deco2{
  right: 49.9%;
  background-color: transparent;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50'%3E%3Cpath d='M50 50H0C39.948 49.906 9.038.269 50 0' fill='%23fff'/%3E%3C/svg%3E") no-repeat  bottom right / contain;
  content: "";
}


@media (max-width:1024px) {
    .banner .squere-container:focus-within ~ .slider-wrapper{
    pointer-events: auto
    }

  .schelude-area{
    width: 100%;
    left: 0;
    background-color: #520303;
  }

  .banner .calendar-button{
    left: 0;
    bottom: 2%;
    display: block;
    max-height: 90px;
    max-width: 90px;
  }

  .calendar-container{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 10;
    border-radius: 0;
    width: calc(100% - 2rem);
    height: 100%;
    transition: opacity 0.7s;
    pointer-events: none;
    align-items: center;
    display: flex;
    justify-content: center;
  }


  .banner.visable .calendar-container{
    opacity: 1;
    pointer-events: auto;
  }

  .banner.date-hovered .schelude-area{
    opacity: 0;
  }

  .schelude-background{
    display: none;
  }

  .banner.date-checked .schelude-area{
    opacity: 1;
  }
  .banner.date-checked .calendar-container{
    opacity: 0;
  }
  @media (orientation:landscape) {
    .banner-deco{
      height: 17%
    }
  }
}
