.slide{
  width: 100%;
  height: 100%;
  transition: opacity 1s ease-in;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: 0;
}



.slide img{
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  object-position: center;
  z-index: 0;
}

.slide::before {
  position: absolute;
  left: 0;
  top: 0;
  content: " ";
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(0,0,0,0.3);
  z-index: 1;
}

.slide.loaded.active, .slide.active.error{
  opacity: 1;
}

.slide .slide-caption{
  text-align: right;
  top: 50%;
  min-width: 40%;
  position: absolute;
  right: 16vmax;
  color: white;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

.f-size1 .slide .slide-caption{
  right: 12vmax;
  top: 40%;
  min-width: 50%;
}

.f-size2 .slide .slide-caption{
  right: 8vmax;
  top: 30%;
  min-width: 60%;
}

.slide .slide-caption .link-wrapper{
  display: flex;
  justify-content: flex-end;
}

.slide .slide-caption a{
  display: block;
  height: 100%;
}

.slide .slide-caption h2, .slide .slide-caption p{
  margin: 0;
}

.slide .slide-caption h2{
  font-size: 2em;
  font-weight: 900;
}


.slide .slide-caption p{
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.slide .slide-caption .container{
  max-width: 60%;

}

@media (max-width:1024px) {

  .slide .slide-caption{
    top: auto;
    bottom: 25%;
    right: 20px;
    font-size: 3.5vw;
  }
  .f-size1 .slide .slide-caption{
    top: auto;
    bottom: 10%;
    right: 20px;
    font-size: 4vw;
  }
  .f-size2 .slide .slide-caption{
    top: auto;
    bottom: 10%;
    right: 20px;
    font-size: 4.5vw;
  }

@media (orientation:landscape) {
  .slide .slide-caption{
    font-size: 2vw;
  }
  .f-size1 .slide .slide-caption{
    font-size: 2.2vw;
        bottom: 25%;
  }
  .f-size2 .slide .slide-caption{
    font-size: 2.4vw;
    bottom: 28%;
  }
}

  .slide .slide-caption .container{
    max-width: 70%;
  }
}
