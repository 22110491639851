header {
  z-index: 31;
  width: 100%;
  top: 0;
  position: fixed;
  background: linear-gradient(45deg, #520303 60%, rgb(53, 47, 69));
  background-size: 100vw 100vh;
  display: flex;
  justify-content: space-between;
}

header::before{
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='51.172' height='48.072'%3E%3Cpath d='M0 0v48.072h.096A50 50 0 0150 0zm50 0a50 50 0 011.172.059V0z' fill='%23520303'/%3E%3C/svg%3E") no-repeat top / contain;
  width: 150px;
  height: 150px;
  position: absolute;
  left: -5px;
  bottom: -150px;
  content: "";
  z-index: -1;
  pointer-events: none;
}

.app.greyscale header{
  opacity: 1;
}

 header .logo{
  width: 40vw;
  object-fit: contain;
  height: 150px;
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  background-image: url("/img/logo.svg");
  outline: none;
  transition: opacity 0.5s;
  position: relative;
}

header a:focus .logo{
  opacity: 0.5;
}

header .mobile-btn{
  z-index: 10;
  height: 4rem;
  width: 4rem;
  padding: 0;
  margin: 0.8rem;
  position: relative;
  background-color: transparent;
  display: none;
  align-self: center;
}


@media (min-width:1025px){
  header .mobile-btn.button-hidden{
    opacity: 0;
    pointer-events: none;
  }
}
@media (max-width:1024px) {
  header .logo{
    height: 10vw;
  }

  header::after{
    display: none;
  }
  header {
    justify-content: space-between;
    overflow: visible;
    background-size: auto;
    background: linear-gradient(45deg, #520303 40%, rgb(53, 47, 69));
  }

  header .mobile-btn{
    width: calc(10vw - 1.6rem);
    height: calc(10vw - 1.6rem);
  }

   header .logo{
     width: 40vw;
     height: 10vw;
   }

  header .mobile-btn{
    display: block;
  }

  header .mobile-btn svg{
    object-fit: cover;
  }

  @media (orientation:portrait) {
    header .mobile-btn{
      width: 10vw;
      height: 10vw;
    }

     header .logo{
       width: 60vw;
       height: 20vw;
     }
  }

  header.sidebar-is-visable .mobile-btn{
    transform: scale(-1);
  }
}


@media (hover:hover) {
  header a:hover .logo,    header  .mobile-btn:hover{
        opacity: 0.5;
  }
}
