main {
  transition: opacity 0.25s;
  min-height: 120vh;
  overflow: hidden;
}

main > div:first-child, main > article{
  margin-top: 10vw;
}


main > div.homepage{
  margin-top: 0;
  margin-bottom: 1rem
}

section{
    position: relative;
}

main .contact-wrapper{
  display: flex;
  justify-content: space-around;
}

main .contact-wrapper h3{
  margin: 0;
}


main .contact-wrapper > div{
  box-shadow: 0 1px 5px rgba(0,0,0,0.5);
  width: 300px;
}

main .contact-wrapper ul{
  list-style: none;
  padding: 0;
}

main .contact-wrapper .contact{
  padding: 10px;
  width: 280px
}

main > div > section{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main > div > section[id="aktualnosci"]{
  min-height: 0;
}


@media (max-width:1024px) {
  main > div > section{
    min-height: 500px;
    display: flex;
    flex-direction: column;
  }

  main > article, main > .bulletin{
    padding-top: 13vw;
  }
  main > div > section{
    min-height: 500px;
  }
  main .contact-wrapper{
    flex-direction: column;
    align-items: center;
  }
  main .contact-wrapper > div{
  margin: 1rem;
  }
  @media (orientation:portrait) {
    main > div:first-child, main > article{
      margin-top: 17vw;
    }
  }
}
