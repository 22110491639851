footer{
  background: linear-gradient(45deg, #520303 40%, rgb(53, 47, 69)) /*zmieniono*/;
  color: white;
  display: flex;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2.5rem;
  padding-top: 2rem;
  position: relative;
  width: 90%;
  justify-content: space-between;
  text-align: left;
  z-index: 30;
  transition: opacity 0.25s;
}

.app.greyscale footer{
  background-image: none;
  background-color: #000;
}

.app.greyscale footer button{
  color: #fff;
}

footer .city-container{
  flex-direction: column;
  position:relative;
  align-items: flex-start;
  max-width: 35%;
}

footer .copyright{
  position: absolute;
  bottom: 0.5rem;
  right: 0;
  width: 100%;
  text-align: center;
}

footer .right-wrapper{
  display: flex;
      margin-left: 1em;
      margin-bottom: 1em;
}

footer ul{
  margin: 0;
  margin-top: 0.5em;
  list-style: none;
  padding: 0;
}

footer li{
font-size: 1em;
margin-bottom: 0.5rem;
}


footer .fav-wrapper{
margin-left: 1rem;
}
footer .contact-wrapper{
  position: relative;
  overflow: hidden;
}

footer .contact-wrapper .button-wrapper{
  width: 100%;
}



footer .contact-wrapper .both-wrapper{
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
}

footer .contact-wrapper button{
  background-color: transparent;
  padding: 0;
  color: white;
  position: relative;
}



footer .contact > div:last-child span{
  display: none;
}

footer .contact{
  margin-top: 0.5em;
}

footer .contact .opening-hours div:first-child{
font-weight: 600;
}

footer .contact .opening-hours .empty+div{
font-weight: 600;
}


footer .contact li{
  margin: 0;
}

footer .contact ul{
  padding: 0;
  margin: 0;
}

footer h3{
  margin-top: 20px;
}
footer .social{
  display: flex;
}

footer .social a{
height: 3vw;
width: 3vw;
margin: 1%;
}

footer .social a:hover div, footer .social a:focus div{
  filter: drop-shadow(0 0 10px #fff);
}

footer .social a::before{
  visibility: hidden;
}

footer .social div{
  width: 100%;
  height: 100%;
  background: center / contain  no-repeat transparent ;
}


@media (max-width:1024px) {

  footer{
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2rem;
    padding-top: 1vmin;
    width: 96%;
  }

  footer .city-container {
    max-width: 45%;
  }

  footer .contact-wrapper .slide-icon{
    height: 5vw;
    width: 5vw;
  }
  footer h3{
    margin-top: 9px;
  }
  footer .contact-wrapper button::before{
    display: none;
  }

  footer .contact-wrapper button{
    font-weight: bold;
  }
  footer .contact-wrapper button:focus{
    background-color: red;
  }

  footer{
    font-size: 0.9em;
  }
  footer .right-wrapper{
    max-width: 45%;
    flex-direction: column;
  }
  footer .contact-wrapper{
  margin-left: 0;
  }
  footer .fav-wrapper{
  margin-left: 0;
  }
  footer .social a{
    height: 10vw;
    width: 10vw;
  }
  @media (orientation:portrait) {
    footer .contact-wrapper .slide-icon{
      height: 10vw;
      width: 10vw;
    }
    footer .social a{
      height: 14vw;
      width: 14vw;
      max-width: 48px;
      max-height: 48px;
    }
  }
}

@media (max-width:600px) {
    footer{
      font-size: 0.6em;
    }
    footer .ui-menu button{
      text-align: right;
      white-space: normal;
      font-size: 1.2rem;
    }
    footer .ui-menu{
      max-width: 95%;
    }
}
