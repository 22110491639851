
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat Black'), local('Montserrat-Black'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_epG3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gfD_u50.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_bZF3gnD_g.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wdhyzbi.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
body{
  margin: 0;
  font-size: 20px;
  color: #424343;
  overflow-y: scroll;
  font-family: "Montserrat", sans-serif;
  background: url("/img/bg-2.svg") no-repeat left top / 30% fixed, url("/img/bg-3.svg") no-repeat 95% 90% / 20% fixed;;
}


.app.greyscale{
    color: #000;
}



h2,h1{
  font-size: 3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

}

.f-size1 .news .show-all, .f-size1 h2,.f-size1 h1{
  font-size: 3.5rem;
}
.f-size2 .news .show-all, .f-size2 h2,.f-size2 h1{
  font-size: 4rem;
}



button{
  font-family: 'Montserrat';
  border: none;
  background-color: white;
  color: #424343;
  cursor: pointer;
  border: none;
}

.app.greyscale button{
    color: #000;
}

button:focus{
  outline: none;
  color: white;
  background-color: #520303;
}


.app {
  text-align: center;
}

/* common use */
html .app .disabled{
  pointer-events: none;
}

html body .app.loading main, html body .app.loading footer{
  opacity: 0;
}
a{
  position: relative;
}
a:link{
  text-decoration: none;
    color: inherit;
}
a:visited{
  color: inherit;
}
a:focus{
   outline: none;
}

.background-img{
  background-image: url("/img/bg.svg");
  background-repeat: repeat-y;
  background-size: 120%;
}

.cms-link{
  color:#520303;
  text-decoration: none;
}

.cms-link:link, .cms-link:visited{
  color:#520303;
  font-weight: bold;
  text-decoration: none;
}
.cms-link:hover, .cms-link:focus{
  text-decoration: underline;
}

.strong-link{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  height: 2rem;
}

.underlined-links a::before, .underlined-btns button::before{
  position: absolute;
  background-color: white;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 3px;
  transform: scale(0);
  transition: transform 0.3s;
}

.underlined-links a:focus::before,.underlined-btns button:focus::before
{
  transform: scale(1);
}

.plus{
  background: url("/img/plus.svg") no-repeat center;
  background-size: contain;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 1rem;
}

.plus.dark{
    background: url("/img/dark_plus.svg") no-repeat center;
    background-size: contain;
}



.app.greyscale img{
  filter: brightness(0.7);
}

.app.greyscale::after{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  backdrop-filter: grayscale(1);
  z-index: 1000;
  pointer-events: none;
  content: "";
}

.app.f-size1{
  font-size: 25px;
}
.app.f-size2{
  font-size: 30px;
}

.app .loading-main{
  position: relative;
  height: 50vh;
  margin-top: 25vh;
  margin-bottom: 25vh;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.app.loading .loading-main{
  transition: opacity 0.3s 1s;
  opacity: 1;
}

@keyframes appear-jump {
  0%{
    opacity: 0;
    transform: translate(-50px,-50px);
  }
  100%{
    transform: scale(1);
    opacity: 1;
  }
}


@keyframes appear-expose {
  0%{
    opacity: 0;
    transform: translate(-50px,-50px);
  }
  100%{
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes appear-slide-left {
  0%{
    transform: translate(-50px,-50px);
    opacity: 0;
  }
  100%{
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes appear-slide-right {
  0%{
    opacity: 0;
    transform: translate(-50px,-50px);
  }
  100%{
    opacity: 1;
    transform: translate(0,0);
  }
}

@media (hover:hover) {
  button:hover{
    outline: none;
    color: white;
    background: rgb(53, 47, 69);
  }
  .underlined-links a:hover::before,.underlined-btns button:hover::before
  {
    transform: scale(1);
  }
}

@media (max-width:1024px) {
  body {
      background: url("/img/bg-2.svg") no-repeat left 25% / 30% fixed, url("/img/bg-3.svg") no-repeat 95% 90% / 20% fixed;;
  }
   .news .show-all, h2,h1{
    font-size: 5vw;
  }

  .f-size1 h2, .f-size1 h1, .f-size1 .news .show-all{
    font-size: 6vw;
  }
  .f-size2 h2, .f-size2 h1, .f-size2 .news .show-all{
    font-size: 7vw;
  }

  @media (orientation:portrait) {
    body {
        background: url("/img/bg-2.svg") no-repeat left 25% / 50% fixed;
    }

    .news .show-all, h2,h1{
      font-size: 10vw;
    }
    .f-size1 h2, .f-size1 h1, .f-size1 .news .show-all{
      font-size: 12vw;
    }
    .f-size2 h2, .f-size2 h1, .f-size2 .news .show-all{
      font-size: 14vw;
    }
}

@media (max-width:1024px) {
  h1,h2,h3{
    max-width: 100%;
  }
}
}
