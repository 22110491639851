article{
  min-height: 100vh;
  transition: opacity 0.5s;
  width: 80%;
  margin-left: 10%;
  text-align: left;
}

article.loading{
  opacity: 0;
}

article section{
  position: relative;
}

article .paragrah{
align-items: center;
display: flex;
width: 100%;
min-height: 100vh;
}

article .right{
flex-direction: row-reverse;
}


article .paragrah .text-wrapper{
  width: 46%;
  margin-right: 8%;
  display: flex;
  align-items: center;
}

article .paragrah .text-wrapper .par{
  position: relative;

}

article .paragrah .text-wrapper .par::after{
top: -1rem;
left: -10vw;
width: 50vmin;
height: 30vmin;
content: "";
background: linear-gradient(45deg, #520303 40%, rgb(53, 47, 69)) /*zmieniono*/33;
position: absolute;
z-index: -1;
}

.app.greyscale article .paragrah .text-wrapper .par::after{
    background-color: #0001;
}

article .paragrah.right .text-wrapper .par::after{
  left: auto;
  right: -10vw;
}

article .right .text-wrapper{
  margin-left: 8%;
  margin-right: 0;
}

article .paragrah .gallery-container{
  display: flex;
  align-items: center;
  width: 46%;
}

article .paragrah .gallery-overview{
  height: 90vh
}

article .player-wrapper{
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;

}

article .player{
  width: 100%;
  height: 40vw;
  background-color: black
}

article .player iframe, article .player video{
  transition: box-shadow 0.3s;
}


article .player iframe:focus, article .player video:focus{
  outline: none;
}

article .player iframe:hover, article .player video:hover, article .player iframe:focus, article .player video:focus{
  box-shadow: 0 0 15px rgba(0,0,0,0.5)
}

article .not-found{
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

article .created-at{
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

article #gallery{
  display: flex;
  flex-direction: column;
  align-items:  center;
  margin-bottom: 2rem;
}

article .gallery-wrapper{
  width: 40vmax;
  height: 30vmax;
}

@media (max-width:1024px) {
  article {
    min-height: 500px;
  }
  article .paragrah .gallery-overview{
    height:90vw;
    max-height: none;
  }
  article .paragrah .gallery-container{
    width: 100%;
  }

  article .paragrah .text-wrapper .par::after{
    left: -5vw;
    height: 50vmin;
  }
  article .paragrah.right .text-wrapper .par::after{
    right: -5vw;
  }

  article{
      font-size: 5vw;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;
  }


  article .paragrah{
      min-height: 85vh;
      flex-direction: column;
  }
  article .paragrah .text-wrapper{
    width: auto;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  article .paragrah .text-wrapper > div{
      max-width: 100%;
  }
  @media (orientation:landscape) {
      article .paragrah .gallery-overview{
        height:50vw;
    }
    article{
        font-size: 3vw;
    }
  }
}
