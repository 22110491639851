.ui-menu {
  display: flex;
  flex-direction: column;
  height: calc(100% - 30px);
  justify-content: space-between;
  margin: 15px;
  z-index: 10;
  border-left: 1px solid #fff;
  padding-left: 10px;
}


.ui-menu button{
  opacity: 0.8;
  width: 4vmin;
  height: 4vmin;
  background-color: transparent;
  background: url("/img/contrast.svg") no-repeat center center / contain;
  transition: transform 0.3s, opacity 0.3s;
}

.ui-menu button:focus {
  transform: scale(1.3);
  opacity: 1;
}

.ui-menu .font-plus-btn{
background-image: url("/img/font-plus.svg");
}

.ui-menu .font-minus-btn{
background-image: url("/img/font-minus.svg");
}

.f-size0 .ui-menu .font-minus-btn{
  pointer-events: none;
  opacity: 0.3;
}

.f-size2 .ui-menu .font-plus-btn{
  pointer-events: none;
  opacity: 0.3;
}

@media (hover:hover) {
  .ui-menu button:hover{
    transform: scale(1.3);
    opacity: 1;
  }
}

@media (max-width:1024px) {
  .ui-container {
    position: fixed;
    right: 0;
    top: auto;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 100;
  }

  .hidden .ui-container{
    display: none;
  }

  .ui-menu {
    padding:  0.5rem;
    height: 30%;
  }
  .ui-menu button{
    width: 5vmax;
    height: 5vmax;
  }
}
