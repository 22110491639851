.calendar-outer{
  color: rgb(255,255,255);
  min-width: 22vw;
}

.calendar-inner{
background-color: #fff1;
left: 0;
right: 0;
color: white;
display: grid;
grid-gap: 0.3vw;
justify-content: center;
grid-template-columns: 6vmin 6vmin 6vmin 6vmin 6vmin;
}

.month-title{
  font-size: 2vw;
  height: 6vw;
  font-weight: bold;
  position: relative;
}

.f-size1 .month-title{
  font-size: 2.2vw;
}
.f-size2 .month-title{
  font-size: 2.4vw;
}

.date-checked .change-month{
  opacity: 0.3;
  pointer-events: none;
}

.calendar-outer .change-month{
  font-size: inherit;
  position: absolute;
  top: 0;
  color: white;
  background-color: transparent;
  display: block;
  height: 100%;
  left: 0;
}

.calendar-outer .change-month:hover, .calendar-outer .change-month:focus{
  color: white;
  background: rgb(53, 47, 69);
  box-shadow: none;
}

.calendar-outer .change-month:nth-child(2){
  left: auto;
right: 0;
}

.wrapper-nearest{
  text-align: left;
  font-weight:600;
  font-size: 1.5vw;
}

.f-size1 .wrapper-nearest{
  font-size: 1.8vw;
}
.f-size2 .wrapper-nearest{
  font-size: 2.1vw;
}

.nearest-event-label{
  padding-left: 2vw;
  margin-top: 2vmin;
  font-weight:600;
}

.nearest-event{
  margin-top: 2vmin;
  align-items: center;
}

.nearest-event > div{
  display: block;
  width: calc(100% - 5vmin);
  min-height: 3vw;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nearest-event .title{
  margin-bottom: 1vmin;
}
@media (min-width:1024px) and (min-aspect-ratio:10/6) {
  .calendar-inner{
    column-gap:0.6vw
  }
}
@media (max-width:1024px) {
  .calendar-outer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
  }
  .wrapper-nearest{
    margin: 1rem;
  }
  .calendar-inner{
    grid-template-columns: 6vw 6vw 6vw 6vw 6vw 6vw 6vw;
  }

  @media (orientation:portrait){
    .calendar-inner{
      grid-template-columns: 11vw 11vw 11vw 11vw 11vw;
    }
    .calendar-outer{
      flex-direction: column;
        max-height: 40vw;
    }
    .wrapper-nearest{
      font-size: 3vw;
      margin: 0;
      max-width: 56vw;
      padding: 1vw;
    }
    .month-title{
      font-size: 4vw;
      height: 12vw
    }
    .f-size1 .wrapper-nearest{
      font-size: 3.6vw;
    }
    .f-size2 .wrapper-nearest{
      font-size: 4.2vw;
    }
    .f-size1 .month-title{
      font-size: 4.4vw;
    }
    .f-size2 .month-title{
      font-size: 4.8vw;
    }
  }
  @media (hover:none) {
    .change-month:hover{
      background-color: white;
      color: rgb(53, 47, 69);
      box-shadow: 5px 5px 7px black;
    }
  }
}
