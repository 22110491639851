.photos{
  min-height: 25vw;
  perspective: 3px;
  margin-bottom: 50px;
  padding-top: 50px;
}

.photos{
  display: grid;
  grid-gap: 2vw;
  grid-template-columns: 30.5vw 30.5vw 30.5vw;
  grid-auto-rows:  30.5vw;
  min-height: 30.5vw;
  padding-left: 2%;
  padding-right: 2%;
  width: calc(93%);
}

.photos-container{
  position: relative;
}

.photos .photos-container .gallery-container .gallery-overview, .photos .photos-container .gallery-container {
  width: 100%;
  height: 100%;
}


.photos .gallery-container .gallery-overview .title{
  top: 10px;
  right: 10px;
  text-align: right;
}

.photos .description{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 50%;
  background-color: #fff;
  pointer-events: none;
}

@media (max-width:1024px) {
  .photos>div:nth-child(3n+2), .photos>div:nth-child(3n+1), .photos>div:nth-child(3n){
  grid-column: auto;
  grid-row: auto;
  }
  .photos{
    grid-gap: 10vw;
    width: 96%;
    grid-template-columns: 100%;
    grid-auto-rows:  80vw;
  }

  .photos .title{
    font-size: 3.7vw;
  }

  .photos .description{
      font-size: 3.4vw;
      transform: translate(0,0)
  }

  .f-size1 .photos .description{
      font-size: 4vw;
    }
  .f-size2 .photos .description{
      font-size: 5vw;
      height: 90%;
    }
    .f-size1 .photos .title{
        font-size: 5vw;
      }
    .f-size2 .photos .title{
        font-size: 6vw;
      }
  @media (orientation:landscape) {
    .photos{
      grid-gap: 5vw;
      width: 96%;

      grid-template-columns: 100%;
      grid-auto-rows:  50vw;
    }
  }
}
