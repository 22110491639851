
nav{
  font-size: 1.5vmax;
  color: #fff;
  height: 100%;
}

.nav-container{
  width: 100%;
}

.app.greyscale nav{
    background-color: #000
}


nav .nav{
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
}

nav .nav a{
  text-decoration: none;
  white-space: nowrap;
  display: inline;
}
nav .nav-main{
  height: 100%;
}

nav .nav-main li{
  display: flex;
  height: 100%;
  position: relative;
}
nav .nav-main>li>a{
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

nav .nav-main li a::before{
  z-index: 100;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
  left: 0;
  top: 0;
}

nav .nav-main>li>.nav-sub + a::after{
  z-index: 100;
  font-size: 2rem;
  position: absolute;
  content: "ˇ";
  text-align: center;
  height: 10px;
  width: 100%;
  left: 0;
  bottom: 30%;
}

nav .nav-main li a:focus::before{
  opacity: 0.1;
}

nav .nav-sub{
  flex-direction: column;
  font-weight: normal;
  opacity: 0;
  transition: opacity 0.3s;
  visibility: hidden;
  position: absolute;
  transform-origin: top;
  top: 100%;
  text-align: left;
}


nav .nav-sub>li>a{
  padding: 1rem;
  width: 100%;
}

nav .nav-main>li>a, nav .nav-main>li>ul{
  flex:1;
}

nav .nav-main>li>a{
  display: flex;
  justify-content: center;
  align-items: center;
}

nav .nav-sub{
  background: linear-gradient(45deg, #520303 40%, rgb(53, 47, 69));
}

@media (max-width:1024px) {
  .nav-container{
    width: auto;
  }

  nav{
    margin-right: 0;
    margin-left: 0;
    position: fixed;
    left: 0;
    top: 0;
    width:100%;
    height: 100vh;
    overflow-y:auto ;
    transition: transform 300ms;
    font-weight: bold;
    background: linear-gradient(45deg, #520303 40%, rgb(53, 47, 69));
  }

  nav.hidden{
    transform: translate(100%);
    z-index: -1
  }


  nav .nav-main > li{
      display: block;
  }
  nav .nav-main > li.li-with-sub{
    display: flex;
    flex-direction: column-reverse;
  }

  .app.greyscale nav .nav-main > li{
      background-color: #000
  }

  .app nav .nav-main li a{
    display: flex;
    height: 50px;
    color: #fff;
    padding: 5px;
    padding-left: 20px;
    align-items: center;
    justify-content: flex-start;
  }


  nav .nav-main{
    display: flex;
    padding: 0;
    grid-gap: 0;
    height: auto;
    flex-direction: column;
    list-style: none;
    position: relative;
    font-size: 1.5em;
  }
  /* .f-size1 .nav-main,   .f-size1 .nav-main{
    padding: 0;
  } */

  .app.greyscale nav .nav-main{
      background-color: #434343
  }

  nav li{
    width: 100%;
    margin-right: 0;
    max-width: none;
  }

  nav .nav-sub{
    visibility: visible;
    position: static;
    opacity: 1;
    background: none;
    background-color: #fff1
  }

  nav .nav-main>li>a, nav .nav-main>li>ul{
    flex:none;
  }

  nav .nav-sub li a{
    height: 40px;
  }


    .app.greyscale   nav .nav-sub li a{
        background-color: #434343
    }
    nav .nav-main>li>.nav-sub + a::after{
      display: none;
    }
}

@media (hover:hover) {
  nav .nav-main>li:hover .nav-sub{
    visibility: visible;
    opacity: 1;
  }
  nav .nav-main>li a:hover::before{
    opacity: 0.1;
  }
}
