.controls{
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 3;
  height: 10%;
}

.controls .control{
  opacity: 1;
  color: #424343;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  outline: none;
  cursor: pointer;
  border-radius: 100%;
  border: 1px solid #424343;
  font-weight: bold;
  margin-right: 10px;
  transition: transform 0.3s;
  position: static;
}

.controls  .control.active{
  transform: scale(1.3);
  border: 2px solid #520303;
  color: #520303;
}

.controls .loader{
height: 5px;
width: 100%;
background: linear-gradient(45deg, #520303 40%, rgb(53, 47, 69)) /*zmieniono*/;
position: absolute;
border-radius: 50px;
left: -5%;
bottom: 1px;
transform:scale(0,1);
opacity: 0;
animation: 7s loadbar linear;
}

.controls .control:focus{
    background-color: #52030355;
}

@keyframes loadbar {
  95%{
    transform: scale(1);
    opacity:1;
  }
  100%{
    transform:scale(0);
    opacity:0;
  }
}

@media (hover:hover) {
  .controls .control:hover{
    background-color: #52030355;
  }
}

@media (max-width:1024px) {
  .controls .control{
    width: 48px;
    height: 48px;
  }
  @media (orientation:landscape) {
    .controls{
      height: 17%
    }
    .controls  .control.active{
      transform: scale(1.2);

    }
  }
}
