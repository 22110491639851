
.calendar-svg .calendar-icon{
  transition: opacity 0.3s;
}

.banner.via-button-slided .calendar-svg .calendar-icon{
  opacity: 0.1;
}

.calendar-svg .left-x, .calendar-svg .right-x{
  transition: transform 0.3s;
  transform-origin: 50% 61%;
  opacity: 0.5;
}

.banner.via-button-slided .calendar-svg .left-x{
  transform: rotate(45deg) scale(1.5);
}

.banner.via-button-slided .calendar-svg .right-x{
  transform: rotate(-45deg) scale(1.5);
}
