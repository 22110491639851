.gallery-container .gallery-overview{
  width: 40vmax;
    height: 30vmax;
  position: relative;
  cursor: pointer;
}

.gallery-container .gallery-overview:focus,.gallery-container .gallery-overview:hover{
  outline: none
}

.gallery-container .gallery-overview figure img{
  flex: 100%;
  object-fit: cover;
  margin: 0;
  max-width: none;
}

.gallery-container .gallery-overview figure img.fitted{
  object-fit: contain;
}

.gallery-container img, .gallery-viewer img{
  transition: opacity 0.5s;
  opacity: 0;
}

.gallery-container img.loaded, .gallery-container img.error, .gallery-viewer img.loaded, .gallery-viewer img.error{
  opacity: 1;
}

.gallery-container .gallery-overview::after{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  opacity: 0;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
  transition: opacity 0.5s;
}

.gallery-container .gallery-overview:focus::after,.gallery-container .gallery-overview:hover::after{
  opacity: 1;
}

.gallery-container .gallery-overview figure {
    overflow: hidden;
    margin: auto;
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.gallery-container figcaption, .gallery-viewer figcaption{
  text-align: center;
}

.gallery-container .gallery-overview .img-caption{
  opacity: 0.5;
}

.gallery-container .gallery-overview .title {
    color: white;
    position: absolute;
    font-size: 2.5vw;
    width: 100%;
    top: calc(50% - 1.25vw);
    pointer-events: none;
}

.gallery-container .gallery-overview .title span{
  position: relative;
}

.gallery-container .gallery-overview .title span::after{
  position: absolute;
  right: 0;
  top: 100%;
  content: "";
  background-color: red;
  width: 100%;
  height: 100%;
  background: url("/img/photos_icon.svg") no-repeat center/contain;
}

.f-size1 .gallery-container .gallery-overview .title {
  font-size: 2.8vw;
}
.f-size2 .gallery-container .gallery-overview .title {
  font-size: 3vw;
}

.gallery-container .gallery-overview .title span{
    filter: drop-shadow(1px 1px 1px black)
}

.gallery-viewer{
  z-index: 40;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(45deg, #520303 40%, rgb(53, 47, 69)) /*zmieniono*/;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  transform: scale(0);
  transition: transform 0.3s;
}

.app.greyscale .gallery-viewer{
    background-color: #fff;
}

.gallery-viewer.active{
  transform: scale(0);
}

.gallery-viewer.transitioned{
    transform: scale(1);
}

.gallery-viewer figure{
  height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

 .gallery-viewer.is-single figure{
  height: 100%;
}

.gallery-viewer figcaption{
  position: absolute;
  bottom: 0;
  background: linear-gradient(45deg, #520303 40%, rgb(53, 47, 69)) /*zmieniono*/;
  width: 100%;
}

.app.greyscale .gallery-viewer figcaption{
    background-color: #fff;
}

.gallery-viewer figure > img{
  object-fit: contain;
  margin: 0;
  max-width: 1000px;
  max-height: 100%;
}

.gallery-viewer button{
  right: 0;
  background-color: transparent;
  position: absolute;
}

.gallery-viewer button{
  opacity: 0.5
}

.gallery-viewer button:hover, .gallery-viewer button:focus{
  opacity: 1;
}

.gallery-viewer .close-btn{
  top:0;
  margin: 5px;
  background-color: transparent;
  height: 10vmin;
  width: 10vmin;
  z-index: 50;
  filter: drop-shadow(0 1px 1px black)
}

.gallery-viewer .tiles-btn{
  top:0;
  left: 0;
  opacity: 1;
  border-radius: 100% 100% 0 0;
  background: url("/img/tile-view.svg") no-repeat center / contain;
  background-size: 70%;
  height: 8vmin;
  width: 8vmin;
  z-index: 50;
  transition: transform 0.5s;
  background-color: #520303;
  /* filter: drop-shadow(0 1px 1px black); */
  transform: translate(0, -7vmin);
}

.gallery-viewer .tiles-btn::after{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: url("/img/no-tile-view.svg") no-repeat center / contain;
  background-color: #520303;
  opacity: 0;
  transition: opacity 0.5s;
  background-size: 60%;
}
.gallery-viewer.tiles-view .tiles-btn::after{
  opacity: 1;

}
.gallery-viewer.tiles-view .tiles-btn{
  transform: translate(0,0);

}

.gallery-viewer .control-bar{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 90%;
  height: 100vh;
  position: absolute;
  padding-left: 5%;
  padding-right: 5%;
  transform: translate(0,80vh);
  transition: transform 0.5s;
}

.gallery-viewer .gallery-grid-wrapper{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: overlay;
}
.gallery-viewer .gallery-grid{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: flex-start;
  }

.gallery-viewer.tiles-view .control-bar{
  transform: translate(0,0);
  background: linear-gradient(45deg, #520303 40%, rgb(53, 47, 69));
}

.gallery-viewer .control{
  position: relative;
  background-color: rgb(255,255,255);
  border: none;
  display: block;
  width: 5vh;
  height: 20vh;
  outline: none;
  cursor: pointer;
  padding: 0;
  transition: opacity 1s;
  background: url("/img/gallery-arrow.svg") no-repeat center / contain;

}

.gallery-viewer .thumb{
  position: relative;
  opacity: 0.9;
  animation: appear-expose 1s both;
  margin-top: calc(10vh - 50px);
}

 .gallery-viewer .thumb img{
  opacity: 1;
  object-fit: contain;
  height: 100px;
  width: 100px;
  background-color: #fff7;
}

 .gallery-viewer .control.inactive{
  opacity: 0;
  box-shadow: none;
  pointer-events: none;
}

 .gallery-viewer .next-slide{
  transform: rotate(180deg);
}

.app.greyscale  .gallery-viewer .control::after{
    background-color: #000;
}

 .gallery-viewer figcaption{
  color: rgb(255,255,255)
}


.gallery-container .gallery-overview figure img{
  max-width: 100%;
  max-height: none;
}

@media (max-width:1024px) {
  .gallery-viewer .thumb{
      margin-top: calc(10vh - 5vmin)
  }

  .gallery-viewer .thumb img{
    height: 10vmin;
    width: 10vmin;
  }
  .gallery-container .gallery-overview{
    width: 40vw;
      height: 30vw;
  }
  @media (orientation:portrait) {
    .gallery-container .gallery-overview{
      width: 70vw;
        height: 50vw;
    }
  }
}
