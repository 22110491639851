.slider{
  position:relative;
  width: 100%;
  height: 100%;
}

.slider .herb{
  height: 20%;
  width: 300px;
  display: block;
  background: url("/img/herb-gabina.svg") no-repeat center / contain;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2
}
@media (max-width:1124px) {
  .slider .herb{
  height: 20%;
  width: 200px;
}
}
@media (max-width:1024px) {
  .slider .herb{
    height: 20%;
    width: 80px;
  }
  @media (orientation:portrait) {
    .slider .herb{
      height: 140px;
      width: 140px;
    }
    .slider .herb{
      right:-70px;
      bottom: -10px;
      background-size: 50%;
      background-position:  10% 10%;
      background-image: url("/img/herb-gabina-textless.svg");
      background-color: white;
      border-radius: 100%;
    }
  }
}
