.day{
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2vw;
  height: 3.5vw;
  width: 3.5vw;
  cursor: pointer;
  transition: opacity 0.2s;
  position: relative;
}
.f-size1 .day{
  font-size: 1.1vw;
}
.f-size2 .day{
  font-size: 1.2vw;
}

.day.moved-true{
  box-shadow: 1px 1px 0 inset rgb(123, 196, 150), -1px -1px 0 inset rgb(123, 196, 150), 1px -1px 0 inset rgb(123, 196, 150), -1px 1px 0 inset rgb(123, 196, 150);
}

.day.ex-true{
  box-shadow: 1px 1px 0 inset, -1px -1px 0 inset, 1px -1px 0 inset, -1px 1px 0 inset;
}

.day.event-true{
  background-color: white;
  color:rgb(53, 47, 69);
}


.day.nearest-day{
  padding-left: 2vw;
  padding-bottom: 0.5vw;
  padding-top: 0.5vw;
  height: auto;
  width: calc(100% - 2vw);
  border-radius: 0;
  background-color: #fff1;
  color: white;
}
.day .dayOfWeek{
  display: block;
  position: absolute;
  top: 60%;
  font-size: 0.7rem;
  opacity: 0.5;
}

.day.nearest-day .dayOfWeek{
  display: none;
}

.day:hover{
  background-color: rgb(53, 47, 69);
  color: white;
}

.day.innactive{
  opacity: 0.3;
  background-color: transparent;
  pointer-events: none;
  color: white;
}

.date-checked .day{
  opacity: 0.3;
  pointer-events: none;
}

.date-checked .day.this-day-checked{
  opacity: 1;
  pointer-events: auto;
}

.day::after{
  border-radius: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top:0;
  left: 0;
  content: "\2713";
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s;
}

.date-checked .day.this-day-checked::after{
    color: rgb(53, 47, 69);
  opacity: 1;
  background-color: white;
}
.day.nearest-day::after{
  border-radius: 0;
}

@media (max-width:1024px){
.day{
  height: 6vw;
  width: 6vw;
  font-size: 3vw;
}
.f-size1 .day{
  font-size: 3.3vw;
}
.f-size2 .day{
  font-size: 3.6vw;
}

.date-checked .day.this-day-checked{
  pointer-events: none;
}

@media (orientation:portrait) {
.day{
  height: 11vw;
  width: 11vw;
  font-size: 5vw;
  }
    .f-size1 .day{
      font-size: 6vw;
    }
    .f-size2 .day{
      font-size: 7vw;
    }
  }
  @media (hover:none) {
    .day:hover{
      background-color: white;
      color: rgb(53, 47, 69);
      box-shadow: 5px 5px 7px black;
    }
  }
}
