
.mobile-icon {
  height: 100%;
  width: 100%;
  z-index: 1;
}

.mobile-icon .edges{
  transition: opacity 0.3s;
}

.menu-visable .edges{
opacity: 0;
}

.mobile-icon .edge-top{
  top:0;
}

.mobile-icon .edge-bottom{
  bottom:0;
}

.mobile-icon div{
  height: 20%;
  width: 100%;
  left: 0;
  background-color: #fff;
  position: absolute;
}

.mobile-icon .center1, .mobile-icon .center2{
  top: 40%;
  transition: transform 0.3s;
  transform-origin: 50% 50%;
}

.menu-visable .center1{
  transform: rotate(45deg);

}

.menu-visable .center2{
  transform: rotate(-45deg);
}
