.tile{
  position: relative;
  transition: opacity 0.5s;
  overflow: hidden;
  width: 100%;
  object-fit: contain;
  text-align: left;
  height: 100%;

}

.tile-inner{
  height: 100%;
  width: 100%;
  position: relative;
}

.tile-inner .background{
  width: 102%;
  height: 102%;
  position: absolute;
  left: -1%;
  top:-1%;
  background-size: cover;
  z-index: 0;
  filter: blur(3px);
  opacity: 0;
  transition: opacity 0.5s linear;
}

.tile.loaded .background, tile.error .background{
  opacity: 1;
}

.tile .description{
  background-color: #fff;
  height: 50%;
  position: absolute;
  font-size: 0.9rem;
  z-index: 2;
  left: 0;
  bottom: 0;
  padding-left: 5%;
  padding-right: 5%;
  width: 40%;
}

.news-container>a:nth-child(3n) .description, .news-container>a:nth-child(3n + 2) .description{
  height: 100%;
  left: auto;
  right: 0;
}

.news-container>a:nth-child(3n) img, .news-container>a:nth-child(3n + 2) img{
  width: 50%;
}

.app.greyscale .tile .description{
    background-color: #000;
}

.f-size1 .tile .description{
  font-size: 1.1rem;
  height: 70%
}
.f-size2 .tile .description{
  font-size: 1.3rem;
  height: 100%
}


.tile .title{
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  height: 35%;
  margin-top: 0;
  margin-bottom: 0;
}

.f-size1 .tile .title{
  font-size: 1.6rem;
}

.f-size2 .tile .title{
  font-size: 2rem;
}

.tile img{
  position: absolute;
  z-index: 1;
  transition: opacity 1s;
  opacity: 0;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.tile img.fitted{
  object-fit: contain;
  object-position: center center;
}

.tile.loaded img, tile.error img{
  opacity: 1;
}

.tile .caption{
  overflow:hidden;
}

.tile .check{
  position: absolute;
  padding-right: 5%;
  text-align: right;
  width: 90%;
  bottom: 5%;
  font-weight: 600;
  font-size: 1rem;
}

.tile .created-at{
  font-size: 0.9rem;
    font-weight: 600;
  position: absolute;
  text-align: center;
  left: 5%;
  bottom: 5%;
}

@media (max-width:1160px) {
  .tile .title{
    font-size: 1.1rem;
  }
  .tile .description{
    font-size: 0.79rem;
    }
  .tile .created-at{
      font-size: 0.79rem;
    }
    .tile .check{
      font-size: 0.79rem;
    }
}

@media (max-width:1024px) {
  .news-container>a:nth-child(n) .description{
    height: 100%;
    left: 0;
    right: auto;
  }

  .news-container>a:nth-child(2n) .description{
    left: auto;
    right: 0;
  }
  .news-container>a:nth-child(n) img{
    width: 50%;
  }

  .news-container>a:nth-child(2n + 1) img{
    left: auto;
    right: 0;
  }

  .tile .title{
    font-size: 3.7vw;
  }
  .tile .check{
    font-size: 3.4vw;
  }

  .tile .created-at{
    font-size: 3.4vw;
  }
  .tile .description{
      font-size: 3.4vw;
      transform: translate(0,0)
  }

  .f-size1 .tile .description{
      font-size: 4vw;
    }
  .f-size2 .tile .description{
      font-size: 5vw;
      height: 90%;
    }
    .f-size1 .tile .title{
        font-size: 5vw;
      }
    .f-size2 .tile .title{
        font-size: 6vw;
      }
  .tile .caption{
    height: 100%;
  }
  @media (orientation:landscape) {
    .tile .title{
      font-size: 3.6vw;
    }
    .tile .check{
      font-size: 3vw;
    }

    .tile .created-at{
      font-size: 3vw;
    }
    .tile .description{
        font-size: 3vw;
    }

    .f-size1 .tile .description{
        font-size: 4vw;
      }
    .f-size2 .tile .description{
        font-size: 5vw;
        height: 90%;
      }
      .f-size1 .tile .title{
          font-size: 5vw;
        }
      .f-size2 .tile .title{
          font-size: 6vw;
        }
  }
}
