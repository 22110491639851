.schelude-inner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.2vw;
  margin: 0.5vw;
  height: 100%;
}

.f-size1 .schelude-inner{
  font-size: 1.4vw;
}
.f-size2 .schelude-inner{
  font-size: 1.6vw;
}

.schelude-inner h2{
  font-size: 1.41em;
  margin: 0;
}

.schelude-inner h3{
  font-size: 1.25em;
  margin: 0;
}

.schelude-inner p{
  margin: 0;
  margin-top: 5px;;
}

.schelude-inner .event{
  margin: 0.5vw;
}

.schelude-inner .row{
  margin: 0.2vw;
}

.schelude-inner .row.canceled .row-title, .schelude-inner .row.canceled .row-name{
  text-decoration: line-through;
  opacity: 0.5
}

.schelude-inner .row.canceled .canceled-after{
  color: rgb(236, 81, 81)


}
.schelude-inner .row.moved .row-title, .schelude-inner .row.moved .row-name{
  text-decoration: line-through;
  opacity: 0.5
}

.schelude-inner .row.moved .canceled-after{
  color: rgb(84, 233, 180)
}

.schelude-inner .moved-to{
  font-weight: 600;
  color: rgb(81, 236, 139)
}

.schelude-inner .row-title{
  font-weight: bold;
}

.schelude-inner .event-summary{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.1)
}

.schelude-inner .event-summary .event-text{
  flex: 1;
}

.schelude-inner .event-summary .img-container{
  width: 15vw;
  height: 10vw;
  position: relative;
  overflow: hidden;
}

.schelude-inner .event-summary img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
  opacity: 0;

}
.schelude-inner .event-summary img.loaded, .schelude-inner .event-summary img.error{
  opacity: 1
}

.schelude-inner .event-summary:hover img{
  transform: scale(1.05);
}

.schelude-inner .event-summary:hover{
  opacity: 0.8;
}

@media (max-width:1024px){
  .schelude-inner{
    font-size: 3.2vw
  }
  .f-size1 .schelude-inner{
    font-size: 3.8vw;
  }
  .f-size2 .schelude-inner{
    font-size: 4.4vw;
  }

  @media(orientation:portrait) {
    .schelude-inner .event-summary .img-container{
      width: 30vw;
      height: 20vw;
    }
  }
  @media(orientation:landscape) {
  .daily-schelude{
    flex: 1;
  }
  .schelude-inner{
    font-size: 1.6vw
  }
  .f-size1 .schelude-inner{
    font-size: 1.9vw;
  }
  .f-size2 .schelude-inner{
    font-size: 2.2vw;
  }
  .event-schelude{
    flex: 2
  }

  .schelude-inner{
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  }
}
