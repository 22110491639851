.news{
  min-height: 25vw;
  width: 100%;
  perspective: 3px;
}


.news.loading .news-container a{
    box-shadow: none;
    pointer-events: none;
}

.news .news-container{
  display: grid;
  grid-gap: 2vw;
  grid-template-columns: 22.5vw 22.5vw 22.5vw 22.5vw;
  grid-auto-rows:  22.5vw;
  min-height: 22.5vw;
  padding-left: 2%;
  padding-right: 2%;
  width: calc(93%);
}

.news-container > a{
  flex: 1;
  display: block;

}

.tile-wrapper{
  position: relative;
}

.news a::after{
  position: absolute;
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
  transition: opacity 0.5s;
}

.news a:focus::after {
  opacity: 1;
}

.news a:focus .description{
    transform: translate(0,0)
}

.description-blue, .news a:focus .description-blue{
    opacity: 1;
}

.news .show-all {
background-color: #520303;
color: #fff;
position: relative;
font-weight: 900;
margin: 5vw;
padding-left: 2vw;
padding-right: 2vw;
padding-top: 1vw;
padding-bottom: 1vw;
transition: opacity 0.3s;
}

.show-all{
  font-size: 3rem
}

.news .show-all::before {
  position: absolute;
  z-index: -1;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
  transition: opacity 0.5s;
}

.news .show-all:focus{
  color: rgb(53, 47, 69);
  background-color: #fff;
}

.news.loading .show-all{
  opacity: 0;
  pointer-events: none;
}

.news .show-all:focus::before{
  opacity: 1;
}

.entered .news .news-title{
  opacity: 1;
}

.news-container>a:nth-child(3n+1){
grid-column: auto / span 2;
grid-row: auto / span 2;
}
.news-container>a:nth-child(3n+2), .news-container>a:nth-child(3n){
grid-column: auto / span 2;
}


.entered .news.render-finish a:nth-child(3n+1){
  opacity: 1;
  animation: appear-jump 0.5s 0.3s both;
}

.entered .news.render-finish a:nth-child(3n+2){
  opacity: 1;
  animation: appear-jump 0.5s 0.6s both;
}

.entered .news.render-finish a:nth-child(3n){
  opacity: 1;
  animation: appear-jump 0.5s 0.9s both;
}

.news .news-title{
  font-size: 5rem;
  text-align: right;
  margin-right: 5%;
  top: -2.3rem;
  position: relative;
}

.omited .news a, .omited .news .news-title{
  opacity: 1;
}

@media (hover:hover) {
  .news a:hover::after {
    opacity: 1;
  }

  .news a:hover .description{
      transform: translate(0,0)
  }

  .news a:hover .description-blue{
      opacity: 1;
  }
  .news .show-all:hover{
    color: rgb(53, 47, 69);
    background-color: #fff;
  }
  .news .show-all:hover::before{
    opacity: 1;
  }
}

@media (max-width:1024px) {
  .news-container>a:nth-child(3n+2), .news-container>a:nth-child(3n+1), .news-container>a:nth-child(3n){
  grid-column: auto;
  grid-row: auto;
  }
  .news .news-container{
    grid-gap: 10vw;
    width: 96%;
    grid-template-columns: 100%;
    grid-auto-rows:  80vw;
  }
  .news .news-title{
    font-size: 2rem;
    text-align: center;
    top: 0;
    margin-right: auto;
  }
  @media (orientation:landscape) {
    .news .news-container{
      grid-gap: 5vw;
      width: 96%;

      grid-template-columns: 100%;
      grid-auto-rows:  50vw;
    }
  }
}
