.bulletin {
  text-align: left;
  margin-bottom: 5rem;
}

.bulletin .bulletin-list a::before {
  background-color: #434243;
}

.bulletin .bulletin-list {
  font-weight: bold;
  margin-left: 10%;
  padding: 0;
}

.bulletin-wrapper {
  width: 46%;
  height: 40vw;
}

.bulletin-wrapper.svg-ok {
  background-image: url("/img/bulletin.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  color: transparent;
  transition: transform 0.5s;
}

.bulletin-wrapper span {
  opacity: 0;
}

.bulletin .bulletin-wrapper .gallery-container {
  width: 100%;
  height: 100%;
}

.bulletin .bulletin-wrapper .gallery-container .gallery-overview{
  height: 100%;
}

.bulletin .bulletin-wrapper .gallery-container .gallery-overview img{
  object-fit: contain;
}

.bulletin-wrapper::before {
  height: 100%;
  opacity: 0.5;
  transform: scale(1, 1);
  transition: opacity 0.5s;
}

.bulletin-wrapper:focus::before {
  opacity: 0;
}

.bulletin-wrapper.image-ok {
  height: 100%;
}

.bulletin-wrapper:focus {
  transform: scale(1.1);
}

@media (hover: hover) {
  .bulletin-wrapper:hover {
    transform: scale(1.1);
  }
  .bulletin-wrapper:hover::before {
    opacity: 0;
  }
  .bulletin-wrapper.image-ok {
    transform: none;
  }
}

@media (max-width: 1024px) {
  .bulletin .bulletin-list {
    margin-left: 0;
  }

  .bulletin-wrapper {
    width: 50%;
    height: 50vw;
  }
  @media (orientation: portrait) {
    .bulletin-wrapper {
      width: 100%;
      height: 110vw;
    }
  }
}
